import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ErrorInfo} from '../../error-handling/engin-error-handler.service';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    SimpleMultiSeries,
    SimpleSeries,
} from '@core/interfaces/system/system-common';
import {DisplaySettings, GraphList} from '../common/pages';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {WorkflowInfo} from '@core/interfaces/common/users';

// (base) Request
export class AnalyzerRequest {
    pageId: string;
    tabId: string;
    filterList: Filter[];
    groupList: {
        fieldKey: string;
        selected: boolean;
    }[];
    sensitivityList: {
        alias: string;
        fieldKey: number;
        selected: boolean;
    }[];
    inputList: {
        fieldKey: string;
        dataType: string;
        value: number;
    }[];
    graphList: GraphList[];

    constructor(filterList: Filter[], groupList: any, sensitivityList: any, inputList: any) {
        this.pageId = '';
        this.tabId = '';
        this.filterList = filterList;
        this.groupList = groupList;
        this.sensitivityList = sensitivityList;
        this.inputList = inputList;
        this.graphList = null;
    }
}

export class AnalyzerAcaRequest extends AnalyzerRequest {
    timeInterval: string;
}

export class AnalyzerRiskForecastRequest extends AnalyzerRequest {
    probabilityType: ProbabilityTypeEnum;
    investmentType: InvestmentTypeEnum;
    prioritizeBy?: PrioritizeByEnum;
    investmentPercent?: number;
}

export class AbstractRiskRequest extends AnalyzerRequest {
    riskType: ConsequenceTypeEnum[];
    startYear: number;
    endYear: number;
}

export class RiskCapitalRequest extends AbstractRiskRequest {}

export class RiskAggregateRequest extends AbstractRiskRequest {}

export enum ProbabilityTypeEnum {
    PDF = 'PDF',
    HR = 'HR',
}
export const ProbabilityTypeLabel = {
    [ProbabilityTypeEnum.PDF]: 'PDF',
    [ProbabilityTypeEnum.HR]: 'HR',
};

export enum InvestmentTypeEnum {
    RunToFailure = 'RUN_TO_FAILURE',
    SetInvestment = 'SET_INVESTMENT',
}
export const InvestmentTypeLabel = {
    [InvestmentTypeEnum.RunToFailure]: 'Run To Failure',
    [InvestmentTypeEnum.SetInvestment]: 'Set Investment',
};

export enum PrioritizeByEnum {
    None = '',
    Economic = 'ECONOMIC',
    Health = 'HEALTH',
    Age = 'AGE',
}
export const PrioritizeByLabel = {
    [PrioritizeByEnum.None]: 'None',
    [PrioritizeByEnum.Economic]: 'Economic',
    [PrioritizeByEnum.Health]: 'Health',
    [PrioritizeByEnum.Age]: 'Calendar Age',
};

export interface ConsequenceConfig {
    configList: ConsequenceCategory[];
}
export interface ConsequenceCategory {
    categoryCode: ConsequenceTypeEnum;
    category: string;
}
export enum ConsequenceTypeEnum {
    All = 'All',
    Financial = 'Financial',
    Customer = 'Customer',
    Environment = 'Environment',
    Collateral = 'Collateral',
    Custom1 = 'Custom1',
    Custom2 = 'Custom2',
    Custom3 = 'Custom3',
    Custom4 = 'Custom4',
    Custom5 = 'Custom5',
}
export const ConsequenceTypeLabel = {
    [ConsequenceTypeEnum.All]: 'All',
    [ConsequenceTypeEnum.Financial]: 'Financial',
    [ConsequenceTypeEnum.Customer]: 'Customer',
    [ConsequenceTypeEnum.Environment]: 'Environment',
    [ConsequenceTypeEnum.Collateral]: 'Collateral',
    [ConsequenceTypeEnum.Custom1]: 'Custom1',
    [ConsequenceTypeEnum.Custom2]: 'Custom2',
    [ConsequenceTypeEnum.Custom3]: 'Custom3',
    [ConsequenceTypeEnum.Custom4]: 'Custom4',
    [ConsequenceTypeEnum.Custom5]: 'Custom5',
};

// Response with error handling
export interface AnalyzerResponseWrapper {
    successFlag: boolean;
    error: ErrorInfo;
    response: AnalyzerResponse[];
}

export interface AnalyzerResponse {
    pageId: string;
    title: string;
    tabId: string;
    graphId: string;
    labels: string[];
    unit: Unit;
    data: any;
    legend: string[];
    highlight: boolean[];
}

export interface BooleanSet {
    [code: string]: boolean;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

export interface DataAvailability {
    fieldPhysical: string;
    fieldName: string;
    valuePhysical: string;
    valueName: string;
    count: number;
    available: number;
    valid: number;
    children: DataAvailability[];
}

export interface DataGapOptions {
    hiClassList: string[];
    hiSubclassList: string[];
    degFactorList: string[];
}

export enum DataGapsType {
    LATEST = "LATEST",
    HISTORY = "HISTORY",
}
export const DataGapsTypeLabel = {
    [DataGapsType.LATEST]: 'Latest',
    [DataGapsType.HISTORY]: 'Full History',
};

export abstract class AnalyzerService {
    // EOL Metrics, Demographics
    abstract getAnalyzerDataAbstract(
        req: AnalyzerRequest,
        studyId: number,
    ): Observable<APIResponse<AnalyzerResponse[]>>;

    // ACA
    abstract getHealthHistorySummary(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getHealthHistoryBreakdown(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getCurrentDataQuality(
        req: AnalyzerRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getDataGapChart(
        workflowItemId: number,
        viewType: DataGapsType,
        queryParams: string,
    ): Observable<APIResponse<SimpleSeries<string, number>[]>>;

    abstract getDataGapList(workflowItemId: number, viewType: DataGapsType): Observable<DataSource>;

    abstract getDataGapListCsv(workflowItemId: number, viewType: DataGapsType): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getDataGapFilterOptions(workflowItemId: number): Observable<APIResponse<DataGapOptions>>;

    // Risk Profile
    abstract getConsequenceConfiguration(workflowItemId: number): Observable<APIResponse<ConsequenceConfig>>;

    abstract getRiskForecastByCategory(
        req: AnalyzerRiskForecastRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getAggregatedRiskByGrouping(
        req: RiskAggregateRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getRiskVsInvestmentRatio(
        req: RiskCapitalRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleSeries<string, number>>>;

    // Reporting
    abstract getAssetClassList(workflowItemId: number): Observable<string[]>;

    // Demo - APM
    abstract getAnalyzerDataAbstractMockSystem(studyId: string): Observable<APIResponse<AnalyzerResponse[]>>;

    abstract getAnalyzerDataAbstractMockStation(studyId: string): Observable<APIResponse<AnalyzerResponse[]>>;

    // Filters
    abstract getFilterFieldOptions(
        workflowItemId: number,
        fieldKey: string,
        search?: string,
        filters?: Filter[],
    ): Observable<APIResponse<FilterFieldOption[]>>;

    // Helpers
    public getActiveStudyId(popoutValue: AnalyzerRequest, activeWorkflowInfo: WorkflowInfo): number {
        const activeStudy = popoutValue.sensitivityList.find((item) => item.selected);
        return activeStudy ? activeStudy?.fieldKey : activeWorkflowInfo.workflowItemList[0].workflowItemId;
    }

    public prepareAnalyzerRequest(
        popoutValue: AnalyzerRequest,
        displaySettings: DisplaySettings,
        activeWorkflowInfo: WorkflowInfo,
    ): AnalyzerRequest {
        const sensitivityList = popoutValue.sensitivityList.map((item) => {
            if (item.fieldKey) return item;
            return {
                ...item,
                fieldKey: activeWorkflowInfo.workflowItemList[0].workflowItemId,
            };
        });

        return {
            ...popoutValue,
            sensitivityList,
            // Add in page, tab, and graphList information
            pageId: displaySettings.pageId,
            tabId: displaySettings.tabId,
            graphList: displaySettings.graphList,
        };
    }
}
