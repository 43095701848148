import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    AnalyzerRequest,
    AnalyzerResponse,
    AnalyzerRiskForecastRequest,
    ConsequenceConfig,
    DataGapOptions, DataGapsType,
    RiskAggregateRequest,
    RiskCapitalRequest,
} from '@core/interfaces/engin/analyzer';
import {HttpService} from '../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    SimpleMultiSeries,
    SimpleSeries,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class AnalyzerApi {
    private readonly prefix = 'analyzer';

    constructor(private api: HttpService) {}

    // EOL Metrics, Demographics
    getAnalyzerDataAbstract(req: AnalyzerRequest, studyId: number): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.post(`${this.prefix}/abstract/${studyId}`, req);
    }

    // ACA
    public getHealthHistorySummary(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/health/history-summary/${groupBy}`, req);
    }

    public getHealthHistoryBreakdown(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/health/history-breakdown/${groupBy}`, req);
    }

    public getCurrentDataQuality(
        req: AnalyzerRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/health/data-quality`, req);
    }

    public getDataGapChart(
        workflowItemId: number,
        viewType: DataGapsType,
        queryParams: string,
    ): Observable<APIResponse<SimpleSeries<string, number>[]>> {
        const finalQueryParams = queryParams == '' ? '' : `?${queryParams}`;
        return this.api.get(`${this.prefix}/${workflowItemId}/health/data-gap/${viewType}/chart${finalQueryParams}`);
    }

    public getDataGapList(workflowItemId: number, viewType: DataGapsType): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${workflowItemId}/health/data-gap/${viewType}/list`);
    }

    public getDataGapListCsv(workflowItemId: number, viewType: DataGapsType): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/${workflowItemId}/health/data-gap/${viewType}/list/csv`);
    }

    public getDataGapFilterOptions(workflowItemId: number): Observable<APIResponse<DataGapOptions>> {
        return this.api.get(`${this.prefix}/${workflowItemId}/health/data-gap/options`);
    }

    // Risk Profile
    public getConsequenceConfiguration(workflowItemId: number): Observable<APIResponse<ConsequenceConfig>> {
        return this.api.get(`${this.prefix}/${workflowItemId}/config/consequence`);
    }

    public getRiskForecastByCategory(
        req: AnalyzerRiskForecastRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/risk/forecast-by-category`, req);
    }

    public getAggregatedRiskByGrouping(
        req: RiskAggregateRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/risk/aggregate`, req);
    }

    public getRiskVsInvestmentRatio(
        req: RiskCapitalRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/risk/risk-investment`, req);
    }

    // Reporting
    getAssetClassList(studyId: number): Observable<string[]> {
        return this.api.get(`${this.prefix}/asset-class-list/${studyId}`);
    }

    // Demo - APM
    getAnalyzerDataAbstractMockSystem(studyId: string): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.post(`${this.prefix}/abstract/${studyId}`, {
            pageId: 'eolmetrics',
            tabId: 'health',
            filterList: [
                {
                    fieldPhysical: 'assetclasscode',
                    options: {
                        'Line Structure': false,
                        'Line Conductor': false,
                        'Circuit Breaker': false,
                        Switchgear: false,
                        'Power Transformer': false,
                        'Overhead Transformer': false,
                        'Underground Transformer': false,
                        Pole: false,
                        'Switch Cubicle': false,
                        'Underground Cable': false,
                        'Overhead Switch': false,
                        'Network Transformer': false,
                    },
                },
            ],
            groupList: [
                {
                    fieldPhysical: 'assetclasscode',
                    selected: true,
                },
                {
                    fieldPhysical: 'region_id',
                    selected: false,
                },
            ],
            sensitivityList: [],
            inputList: [
                {
                    namePhysical: 'eolPeriod',
                    dataType: 'integer',
                    value: '10',
                },
            ],
            graphList: [
                {
                    graphId: 'economic_summary',
                    unit: 'currency',
                },
                {
                    graphId: 'economic_status',
                    unit: 'currency',
                },
                {
                    graphId: 'health_summary',
                    unit: 'currency',
                },
                {
                    graphId: 'age_summary',
                    unit: 'currency',
                },
            ],
        });
    }

    getAnalyzerDataAbstractMockStation(studyId: string): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.post(`${this.prefix}/abstract/${studyId}`, {
            pageId: 'eolmetrics',
            tabId: 'health',
            filterList: [
                {
                    fieldPhysical: 'assetclasscode',
                    options: {
                        'Line Structure': false,
                        'Line Conductor': false,
                        'Circuit Breaker': false,
                        Switchgear: false,
                        'Power Transformer': false,
                        'Overhead Transformer': false,
                        'Underground Transformer': false,
                        Pole: false,
                        'Switch Cubicle': false,
                        'Underground Cable': false,
                        'Overhead Switch': false,
                        'Network Transformer': false,
                    },
                },
            ],
            groupList: [
                {
                    fieldPhysical: 'assetclasscode',
                    selected: true,
                },
                {
                    fieldPhysical: 'region_id',
                    selected: false,
                },
            ],
            sensitivityList: [],
            inputList: [
                {
                    namePhysical: 'eolPeriod',
                    dataType: 'integer',
                    value: '10',
                },
            ],
            graphList: [
                {
                    graphId: 'economic_summary',
                    unit: 'currency',
                },
                {
                    graphId: 'economic_status',
                    unit: 'currency',
                },
                {
                    graphId: 'health_summary',
                    unit: 'currency',
                },
                {
                    graphId: 'age_summary',
                    unit: 'currency',
                },
            ],
        });
    }

    // Filters
    public getFilterFieldOptions(
        workflowItemId: number,
        fieldKey: string,
        search: string = '',
        filters: Filter[] = [],
    ): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/options/filters/field`, {
            fieldKey: fieldKey,
            searchString: search,
            filterParams: filters,
            maxItems: 10000,
        });
    }
}
