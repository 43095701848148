import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
    AnalyzerRequest,
    AnalyzerResponse,
    AnalyzerRiskForecastRequest,
    AnalyzerService,
    ConsequenceConfig,
    DataGapOptions, DataGapsType,
    RiskAggregateRequest,
    RiskCapitalRequest,
} from '@core/interfaces/engin/analyzer';

import {AnalyzerApi} from '../api/analyzer.api';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    SimpleMultiSeries,
    SimpleSeries,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class AnalyzerServiceImpl extends AnalyzerService {
    constructor(private api: AnalyzerApi) {
        super();
    }

    // EOL Metrics, Demographics
    getAnalyzerDataAbstract(req: AnalyzerRequest, studyId: number): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.getAnalyzerDataAbstract(req, studyId);
    }

    // ACA
    public getHealthHistorySummary(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getHealthHistorySummary(req, workflowItemId, groupBy);
    }

    public getHealthHistoryBreakdown(
        req: AnalyzerRequest,
        workflowItemId: number,
        groupBy: string,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getHealthHistoryBreakdown(req, workflowItemId, groupBy);
    }

    public getCurrentDataQuality(req: AnalyzerRequest, workflowItemId: number): Observable<APIResponse<any>> {
        return this.api.getCurrentDataQuality(req, workflowItemId);
    }

    public getDataGapChart(
        workflowItemId: number,
        viewType: DataGapsType,
        queryParams: string,
    ): Observable<APIResponse<SimpleSeries<string, number>[]>> {
        return this.api.getDataGapChart(workflowItemId, viewType, queryParams);
    }

    public getDataGapList(workflowItemId: number, viewType: DataGapsType): Observable<DataSource> {
        return this.api.getDataGapList(workflowItemId, viewType);
    }

    public getDataGapListCsv(workflowItemId: number, viewType: DataGapsType): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.getDataGapListCsv(workflowItemId, viewType);
    }

    public getDataGapFilterOptions(workflowItemId: number): Observable<APIResponse<DataGapOptions>> {
        return this.api.getDataGapFilterOptions(workflowItemId);
    }

    // Risk Profile
    public getConsequenceConfiguration(workflowItemId: number): Observable<APIResponse<ConsequenceConfig>> {
        return this.api.getConsequenceConfiguration(workflowItemId);
    }

    public getRiskForecastByCategory(
        req: AnalyzerRiskForecastRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getRiskForecastByCategory(req, workflowItemId);
    }

    public getAggregatedRiskByGrouping(
        req: RiskAggregateRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getAggregatedRiskByGrouping(req, workflowItemId);
    }

    public getRiskVsInvestmentRatio(
        req: RiskCapitalRequest,
        workflowItemId: number,
    ): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.getRiskVsInvestmentRatio(req, workflowItemId);
    }

    // Reporting
    getAssetClassList(studyId: number): Observable<string[]> {
        return this.api.getAssetClassList(studyId);
    }

    // Demo - APM
    getAnalyzerDataAbstractMockSystem(studyId: string): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.getAnalyzerDataAbstractMockSystem(studyId);
    }

    getAnalyzerDataAbstractMockStation(studyId: string): Observable<APIResponse<AnalyzerResponse[]>> {
        return this.api.getAnalyzerDataAbstractMockStation(studyId);
    }

    // Filters
    public getFilterFieldOptions(
        workflowItemId: number,
        fieldKey: string,
        search?: string,
        filters?: Filter[],
    ): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.getFilterFieldOptions(workflowItemId, fieldKey, search, filters);
    }
}
